import { createMachine } from "xstate";

export const showModalMachine = createMachine({
  id: "showModal",
  initial: "hidden",
  states: {
    hidden: {
      on: {
        TOGGLE_SIGNIN_MODAL: "signinVisible",
        TOGGLE_REGISTER_MODAL: "registerVisible",
      },
    },
    signinVisible: {
      on: {
        TOGGLE_SIGNIN_MODAL: "hidden",
      },
    },
    registerVisible: {
      on: {
        TOGGLE_REGISTER_MODAL: "hidden",
      },
    },
  },
  predictableActionArguments: true,
});
