import React, { useState } from "react";
import { auth } from "../Firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import whiteIcon from "../assets/low-carb-meals-white-icon.png";

const Signin = ({ currentState, onClose, userSend, loginStatusSend }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authError, setAuthError] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        //console.log(userCredential);
        loginStatusSend({ type: "LOGIN_SUCCESS" }); // Send LOGIN_SUCCESS event instead of using setIsLoggedIn
        //console.log("LOGIN_SUCCESS event sent");
        onClose();
      })
      .catch((error) => {
        let loggedError = error.message;
        //console.log("Error start", loggedError, "Error end");
        loggedError = loggedError.replace(
          "Firebase: Error (auth/wrong-password).",
          "User/Password Authentication Failure. Try Again"
        );

        setAuthError(loggedError);
      });
  };

  return (
    <div className="w-full">
      <form className="flex flex-col">
        {/* Check if user is logged in */}
        {currentState === "isLoggedIn" ? (
          <span>User {email} Authenticated</span>
        ) : (
          <>
            <div className="pb-4 text-black font-bold ">
              <div className="flex items-center">
                <img
                  src={whiteIcon}
                  alt="White Icon"
                  className="w-16 h-16" // Change the numbers to control the size
                />
                <span className="ml-2">Sign In To LowCarbMeals</span>
              </div>
            </div>

            {authError && <p className="text-red-500">{authError}</p>}
            <div className="w-full flex flex-col space-y-2 mb-2">
              <input
                className="text-black border-slate-300 border-2 p-1 w-full rounded-md"
                type={"email"}
                placeholder="Enter Account Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              ></input>
              <input
                className="text-black border-slate-300 border-2 p-1 w-full rounded-md"
                type={passwordVisible ? "text" : "password"}
                placeholder="Enter Account Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="mt-2 text-left text-sm text-red-500 focus:outline-none"
              >
                {passwordVisible ? "Hide Password" : "Show Password"}
              </button>
            </div>
            <div className="w-full flex text-cen flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 space-x-0 sm:space-x-2">
              <button
                className="bg-custom-300 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-2 ease-linear transition-all duration-150 w-full sm:w-auto"
                onClick={signIn}
              >
                Sign In
              </button>
              <button
                className="bg-custom-300 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-2 ease-linear transition-all duration-150 w-full sm:w-auto"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default Signin;
