// Firebase
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAlEWX9UJHj2XLTOzhmCvPmMLSD1SgKW60",
  authDomain: "lowcarbmealsdotnet.firebaseapp.com",
  projectId: "lowcarbmealsdotnet",
  storageBucket: "lowcarbmealsdotnet.appspot.com",
  messagingSenderId: "960138813243",
  appId: "1:960138813243:web:311898db56a9197200a11e",
  measurementId: "G-8F2STPL6NC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

//const analytics = getAnalytics(app);

/* var firebase = require("firebase");
var firebaseui = require("firebaseui");

// Initialize the FirebaseUI Widget using Firebase.
var ui = new firebaseui.auth.AuthUI(firebase.auth());

ui.start("#firebaseui-auth-container", {
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
    },
  ],
  // Other config options...
}); */

// end Firebase
