import { createMachine, actions } from "xstate";
const { log } = actions;

const isUserLoggedIn = () => {
  const loggedIn = localStorage.getItem("isLoggedIn");
  return loggedIn === "true";
};

export const loginStatusMachine = createMachine({
  initial: isUserLoggedIn() ? "isLoggedIn" : "isLoggedOut",
  states: {
    isLoggedOut: {
      on: {
        LOGIN_SUCCESS: {
          target: "isLoggedIn",
          actions: [
            log(""),
            () => {
              localStorage.setItem("isLoggedIn", true);
            },
          ],
        },
        LOGIN_FAILED: {
          target: "loginFailed",
        },
      },
    },
    isLoggedIn: {
      on: {
        LOGOUT: {
          target: "isLoggedOut",
          actions: [
            () => {
              localStorage.setItem("isLoggedIn", false);
            },
          ],
        },
      },
    },
    loginFailed: {
      on: {
        RETRY_LOGIN: {
          target: "isLoggedOut",
        },
      },
    },
  },
  predictableActionArguments: true,
});
