import { createMachine } from "xstate";

export const userMachine = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QFdZgE4FkCGBjAFgJYB2YAdMQPYCqa6AxADIDyA4gJIByA2gAwC6iUAAdKsQgBdClYkJAAPRAEYAbABYyvAEwqA7NoAcB3gYCsATgDMBgDQgAnst0HNatZaWnL1pZbW7dAF9Au1QMHAIScnxsWFoMJjZmagAVPkEkEFFxKRk5RQRVDW09Q2MzK1sHRAMlMn8tE2cVXiVeXTUtYJCQKgg4OTCsPCJSOWzJaVlMgoBaFTtHBHng0LoI0fIqePRxsUm8mcROxeU2sgsVJQMVD3NzG-MgnqGNqLIYuLo9nKn8xC09zIli0+l0WiU7lMpn8p0Kvk05iuNzuDxUT26gSAA */
  id: "userMachine",
  initial: "noUser",
  context: {
    user: null,
  },
  states: {
    noUser: {
      on: {
        LOGIN: {
          target: "hasUser",
          actions: "setUser",
        },
      },
    },
    hasUser: {
      on: {
        LOGOUT: {
          target: "noUser",
          actions: "clearUser",
        },
      },
    },
  },
  actions: {
    setUser: (context, event) => {
      context.user = event.user;
    },
    clearUser: (context) => {
      context.user = null;
    },
  },
  predictableActionArguments: true,
});
