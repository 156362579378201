// menuMachine.js
import { createMachine, assign } from "xstate";

export const menuMachine = createMachine({
  id: "menu",
  initial: "closed",
  context: {
    menuOpen: false,
  },
  states: {
    closed: {
      on: {
        TOGGLE: {
          target: "open",
          actions: assign({ menuOpen: (_) => true }),
        },
      },
    },
    open: {
      on: {
        TOGGLE: {
          target: "closed",
          actions: assign({ menuOpen: (_) => false }),
        },
      },
    },
  },
  predictableActionArguments: true,
});
