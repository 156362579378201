import React from "react";

function FoodItem(props) {
  const { name, value, checked = false, onChange } = props;
  return (
    <span className="flex p-1">
      <span className="flex items-center gap-1">
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5 text-gray-600"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        {name}
      </span>
    </span>
  );
}


export default FoodItem;
