import React from "react";

function OtherFoodItems({ paramFromInput, setParamFromInput }) {
  return (
    <input
      type="text"
      name="otherFoods"
      onChange={(e) => setParamFromInput(e.target.value)}
      placeholder="Lobster, Shrimp, Etc."
      value={paramFromInput}
      className="border pl-2 pr-2 w-full min-w-0 md:min-w-full lg:min-w-full xl:min-w-full rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
    />
  );
}

export default OtherFoodItems;
