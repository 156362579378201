import React, { useState, useEffect } from "react";
import BarLoader from "react-spinners/BarLoader";
import modalLogo from "../assets/brandmark-design-black.png";

function FindingLoader(props) {
  const { setIsLoading, abortController } = props;

  const [loadingText, setLoadingText] = useState(
    " Please Hold On While We Find Your Next Amazing Recipe "
  );
  const [loaderColor, setLoaderColor] = useState("rgb(255, 216, 138)");
  const [textColor, setTextColor] = useState("text-black");

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingText("We're Pulling Together Relevant Nutritional Information");
      setLoaderColor("rgb(100, 100, 255)"); // Change this to the desired color
      setTextColor("text-black");
    }, 7000);

    return () => clearTimeout(timer);
  }, []);

  // Function to handle cancel button click
  const handleCancel = () => {
    setIsLoading(false);
    // Add any additional actions needed to cancel the submit
    abortController.abort();
  };

  return (
    <div className="fixed inset-0 backdrop-blur-md flex items-center justify-center p-6">
      <div className="flex flex-col items-center rounded-lg p-4 sm:p-2 pt-10 min-w-screen md:min-w-0 md:mx-auto md:flex md:items-center md:justify-center">
        <img
          src={modalLogo}
          width="50%"
          className="pb-2"
          alt="LowCarbMeals Logo"
        />
        <BarLoader loading width="50%" height={10} color={loaderColor} />
        <div
          className={`font-bold antialiased text-xl sm:text-lg md:text-lg p-2 text-center ${textColor}`}
        >
          This Will Take A Hot-Minute!
          <br /> {loadingText}
        </div>
        <button
          className="bg-custom-300 text-black active:bg-amber-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-2 ease-linear transition-all duration-150 w-full sm:w-auto"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default FindingLoader;
