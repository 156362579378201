import React from "react";
import lowCarbMealsImage from "./../assets/low-carb-meals.png";

function DisplayLogo(props) {
  const { src: logoImage } = props;

  return (
    <div className="relative mb-4">
      <div className="h-2/6">
        <img
          src={logoImage}
          className="max-w-full sm:w-25 rounded object-scale-down shadow-lg"
          alt="By Travis Yewell on Unsplash"
          id="title-image"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      </div>
      <div
        className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center"
        id="title"
      >
        <img
          src={lowCarbMealsImage}
          alt="Low Carb Meals"
          className="max-w-[200px] md:max-w-[350px] lg:max-w-[350px] object-scale-down"
        />
      </div>
    </div>
  );
}

export default DisplayLogo;
